import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

function clickCreateUser(e) {
    const target = e.currentTarget.closest('.nav__create-user');

    if (target) {
        if (target.classList.contains('js-show')) {
            target.classList.remove('js-show');
        } else {
            target.classList.add('js-show');
        }
    }
}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }

        const navCreateUser = nav.querySelector('.nav__create-user > svg');

        if (navCreateUser) {
            navCreateUser.addEventListener('click',clickCreateUser);
        }
    }
}
