export function setupEventtickers() {
    const newsTickers = document.querySelectorAll('.event-ticker');

    Array.from(newsTickers).forEach(newsTicker => {
        const items = Array.from(newsTicker.querySelectorAll('li'));
        let hover = false;

        function itemAnimationEnd(e) {
            const item = e.currentTarget;

            item.classList.remove('js-active');
            item.classList.remove('js-hide');
            item.removeEventListener('transitionend', itemAnimationEnd);
        }

        setInterval(function () {
            const _items = arguments[0];
            const _hover = arguments[1];
            const activeItem = _items.filter(x => x.classList.contains('js-active'))[0];
            const activeIndex = _items.findIndex(x => x == activeItem);
            let newActiveIndex = -1;

            if (!hover) {
                if (activeIndex + 1 < items.length) {
                    newActiveIndex = activeIndex + 1;
                } else {
                    newActiveIndex = 0;
                }

                activeItem.classList.add('js-hide');
                activeItem.addEventListener('transitionend',itemAnimationEnd);

                items[newActiveIndex].classList.add('js-active');
            }
        }.bind(this,items,hover), 5000);

        function enterNewsTicker() {
            hover = true;
        }

        function leaveNewsTicker() {
            hover = false;
        }

        newsTicker.addEventListener('mouseenter', enterNewsTicker);
        newsTicker.addEventListener('mouseleave', leaveNewsTicker);
    });
}