import { onWindowResize, currentBreakpoint, currentBreakpointIndex } from './utils/windowResize';
import { onScroll, scrollTop } from './utils/scroll';
import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { initVhUnitOverwrite, isTouchDevice, isIE11 } from './utils/helpers';
import { setupEventtickers } from './components/eventticker';
//import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
//import { setupGalleries } from './components/gallery';
//import { version } from '../../package.json';
//import { setupAnchorLinkScroll } from './components/anchors';
//import { setupFilters } from './components/filter';
//import { setupOverlay } from './components/overlay';
//import { setupTabs } from './components/tabs';
//import { setupYTVideos } from './components/video-yt';
//import { setupSliders } from './components/slider';
//import { watchFormFields } from './components/form';

// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    if (isTouchDevice()) {
        document.body.classList.add('js-is-touch');
    }

    if (isIE11) {
        document.body.classList.add('js-ie-11');
    }

    //setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {
        initVhUnitOverwrite();

        setupLazyLoading();

        setupEventtickers();

        //setupAccordions();

        //setupFilters();

        //setupOverlay();

        setupVideos();

        //setupGalleries();

        //setupYTVideos();

        //setupTabs();

        //setupSliders();

        //watchFormFields();
    });

    // Simulated CookieBot function-call via GTM
    //window.showCookieBanner();

    // onWindowResize event demo
    // I recommend you remove this before going live ;)
    onWindowResize(() => {
        //window.console.log('Resize Event (throuttled)', {currentBreakpoint, currentBreakpointIndex});
    });

    // onScroll event demo
    // Remove when you get tired of the console spam
    let prevScroll = 0;
    const header = document.querySelector('.header');

    if (header) {
        onScroll(() => {
            if (scrollTop > prevScroll && scrollTop > header.offsetHeight) {
                document.body.classList.add('js-scrolled');
            } else {
                document.body.classList.remove('js-scrolled');
            }

            prevScroll = scrollTop;
        });
    }

    //console.info(`fong kører - nedkogt til version ${version}`);

    window.addEventListener('load', () => {
        // Polyfill for using svg spritesheet in oldIE
        svg4everybody();
    });
}

init();
